<template>
  <div class="mask">
    <div class="LeaveMessage" ref="valuation">
      <div class="title" v-drag="valuation">校验用户名</div>
      <div class="layer-content">
        <div class="leave-message">
          <div class="message-title">请输入您的账号:</div>
          <label>
            <input type="text" v-model="username" />
          </label>

          <div class="message-title">{{ errorMsg }}</div>
        </div>
      </div>
      <div class="send">
        <a
          class="submit"
          @click="submit"
          :style="{ 'background-color': backgroundColor }"
        >
          提交
        </a>
      </div>
      <span class="resize"></span>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";

export default {
  props: {
    groupId: {
      // 菜单数据
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  setup(props, context) {
    let api = inject("api");
    let store = inject("store");

    const valuation = ref(null);
    const errorMsg = ref(null);

    const backgroundColor = ref(store.state.config.style.backgroundColor);
    const username = ref(null);
    // 提交
    const submit = () => {
      api
        .validClient({ groupId: props.groupId, username: username.value })
        .then(() => {
          context.emit("processChat");
        })
        .catch(() => {
          errorMsg.value = "请联系info@awcwin.com";
        });
    };

    return {
      errorMsg,
      valuation,
      backgroundColor,
      username,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.LeaveMessage {
  width: 458px;
  height: 518px;
  background-color: white;

  .title {
    height: 42px;
    line-height: 42px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    background-color: #f8f8f8;
    border-radius: 2px 2px 0 0;
  }
  .layer-content {
    height: 418px;
    .leave-message {
      .message-title {
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        padding: 20px;
      }
      label {
        display: block;
        line-height: 30px;
        padding: 10px;
        span {
          width: 100px;
          text-align: right;
          display: inline-block;
          font-size: 14px;
        }
        input,
        textarea {
          width: 240px;
          height: 30px;
          border: 1px solid #eee;
          border-radius: 3px;
          vertical-align: middle;
          padding: 3px;
          max-width: none;
          background: #f9fafc;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          overflow: visible;
        }
        .content {
          height: 90px;
          resize: none;
        }
      }
    }
  }
}
.send {
  padding-top: 10px;
  text-align: center;
  .submit {
    user-select: none;
    height: 28px;
    line-height: 28px;
    margin: 5px 5px 0;
    padding: 8px 15px;
    border-radius: 2px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
  }
  .submit:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}
</style>
