<template>
  <div class="emoji">
    <ul>
      <li
        v-for="(item, index) in emojiList"
        :key="index"
        @click="$emit('on-select', index)"
      >
        <img :src="item" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const emojiList = reactive([]);
    for (let i = 0; i < 104; i++) {
      emojiList.push(require(`../assets/img/emotion/${i}.gif`));
    }
    return {
      emojiList,
    };
  },
};
</script>

<style lang="scss" scoped>
.emoji {
  border-top: 1px solid #ededed;
  background-color: #fff;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 25px;
      height: 25px;
      cursor: pointer;
      img {
        margin: 2px;
      }
    }
  }
}
</style>
