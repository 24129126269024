<template>
  <div class="chat-input" v-if="chatState === 0 || chatState === 2">
    <div class="emoji_box">
      <Emoji v-show="emojiShow" @on-select="selectEmoji" />
    </div>
    <div class="input-tools">
      <label id="emotion" @click="emojiShow = !emojiShow">
        <i class="iconfont iconsmile" />
      </label>
      <form>
        <label>
          <i class="iconfont iconpic" />
          <input
            accept="image/jpg,image/png,image/gif,image/jpeg"
            name="file"
            type="file"
            id="uploadImg"
            @change="handleImgInput"
          />
        </label>
      </form>
      <label @click="showValuation">
        <i class="iconfont iconfavorites" />
      </label>
    </div>
    <div class="input-text">
      <label for="textMessage" />
      <textarea
        v-model="textarea"
        autofocus="autofocus"
        maxlength="1000"
        id="textMessage"
        placeholder="请输入"
        @keydown.enter.exact="handleKeyDownEnter"
        @keydown.ctrl.enter.exact="handleKeyDownCtrlEnter"
      />
    </div>
    <div class="input-btn">
      <div class="drop-btn" :class="{ disable: !sendAble }">
        <a id="sendMessage" class="drop-title" @click="sendMsg">发送</a>
        <a id="changeType" class="drop-menu">
          <i
            class="iconfont iconarrow-down"
            @click="dropMenuShow = !dropMenuShow"
          />
          <ul id="sendType" v-show="dropMenuShow">
            <li @click="changeSend('enter')">
              <span>
                <i v-show="sendKey === 'enter'"> √ </i>
              </span>
              按Enter键发送消息
            </li>
            <li @click="changeSend('ctrl+enter')">
              <span>
                <i v-show="sendKey === 'ctrl+enter'">√</i>
              </span>
              按Ctrl + Enter键发送消息
            </li>
          </ul>
        </a>
      </div>
      <div class="input-count">
        输入字数
        <span id="textCount">{{ inputNumber }}</span>
        /1000
      </div>
    </div>
  </div>
  <div class="chat-disabled" v-if="chatState === 3">对话已关闭</div>
  <div class="chat-black" v-if="chatState === 4">{{ errorMsg }}</div>
  <div class="chat-black" v-if="chatState === 5">
    暂无可用客服,排队等待中... 当前：{{ queue }}人
  </div>
</template>

<script>
import { ref, inject, watch, computed } from "vue";
import Emoji from "./Emoji";
export default {
  components: {
    Emoji,
  },
  props: {
    queue: {
      // 菜单数据
      type: Number,
      required: false,
      default() {
        return 0;
      },
    },
  },
  emits: ["on-input"],
  setup(props, context) {
    let api = inject("api");
    let store = inject("store");
    let util = inject("util");

    const chatState = computed(() => {
      return store.state.chatState;
    });
    const emojiShow = ref(false);
    const textarea = ref("");
    const sendAble = computed(() => textarea.value.trim().length);
    const dropMenuShow = ref(false);
    const sendKey = ref(localStorage.getItem("sendKey") || "enter");
    const inputNumber = computed(() =>
      sendAble.value ? textarea.value.length : 0
    );

    const errorMsg = computed(() => {
      return store.state.errorMsg;
    });
    function selectEmoji(index) {
      emojiShow.value = false;
      context.emit("on-input", {
        messageType: "EMOTICON",
        message: index,
      });
    }
    function handleImgInput(e) {
      util.uploadImg(e, function (data) {
        e.target.value = "";
        context.emit("on-input", {
          messageType: "PICTURE",
          message: data.fileBase64,
        });
      });
    }
    function showValuation() {
      store.action.updateChatState(2);
    }
    function sendMsg() {
      if (!textarea.value.trim()) {
        return;
      }
      // 播放声音
      util.playAudio("SEND_MESSAGE");
      context.emit("on-input", {
        messageType: "TEXT",
        message: textarea.value,
      });
      textarea.value = "";
    }
    function handleKeyDownEnter(e) {
      e.preventDefault();
      if (!sendAble.value) {
        return;
      }
      if (sendKey.value === "ctrl+enter") {
        textarea.value += "\n";
        return;
      }
      // submit
      sendMsg();
    }
    function handleKeyDownCtrlEnter(e) {
      e.preventDefault();
      if (!sendAble.value) {
        return;
      }
      if (sendKey.value === "enter") {
        textarea.value += "\n";
        return;
      }
      // submit
      sendMsg();
    }
    function changeSend(key) {
      localStorage.setItem("sendKey", key);
      sendKey.value = key;
      dropMenuShow.value = false;
    }
    function continueTalking() {
      store.action.updateChatState(0);
    }
    // input输入抖动
    let debounceInput = util.deBounce(function (textarea) {
      api.sendInputMessage({ message: textarea });
    }, 1000);
    watch(textarea, (textarea) => {
      // 输入状态发送到后台
      debounceInput(textarea);
    });
    return {
      chatState,
      errorMsg,
      emojiShow,
      textarea,
      sendAble,
      dropMenuShow,
      sendKey,
      inputNumber,
      selectEmoji,
      handleImgInput,
      showValuation,
      handleKeyDownEnter,
      handleKeyDownCtrlEnter,
      sendMsg,
      changeSend,
      continueTalking,
    };
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .input-tools {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-around;
    label {
      margin: 0 !important;
    }
  }
  .chat-input {
    height: 40px;
    display: flex;
    .input-text {
      height: 40px;
      line-height: 40px;
      flex: 1;
      textarea {
        line-height: 24px;
      }
    }
    .emoji_box {
      bottom: 40px;
    }
    .input-btn .drop-btn {
      width: 40px !important;
      a {
        width: 40px !important;
        float: right !important;
        text-align: right !important;
        color: #666 !important;
      }
      #changeType {
        display: none;
      }
      background-color: transparent !important;
      color: #666;
    }
  }
  .input-count {
    display: none;
  }
}
.chat-input {
  height: 99px;
  border-top: 1px solid #ededed;
  position: relative;
  .emoji_box {
    position: absolute;
    bottom: 100px;
  }
  .input-text {
    width: 100%;

    textarea {
      display: block;
      height: 100%;
      padding: 8px 10px;
      outline: 0;
      background-color: #fff;
      width: 100%;
      border: none;
      font-size: 13px;
      box-sizing: border-box;
    }
  }
  .input-tools {
    label {
      width: 24px;
      height: 18px;
      line-height: 18px;
      color: #aaa;
      float: left;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin: 10px 10px 0 0;
      input {
        width: 18px;
        height: 18px;
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  .input-btn {
    line-height: 40px;
    height: 40px;
    text-align: right;
    padding-right: 10px;
    .input-count {
      color: #aaa;
      float: right;
      line-height: 40px;
      font-size: 12px;
      margin-right: 10px;
    }
    .drop-btn {
      width: 80px;
      height: 30px;
      float: right;
      line-height: 30px;
      margin-top: 5px;
      background-color: #007aff;
      &.disable {
        background-color: #f0f0f0;
        #sendMessage {
          cursor: default;
        }
      }
      a {
        color: #fff;
        display: inline-block;
        height: 30px;
        cursor: pointer;
      }
      .drop-title {
        float: left;
        width: 60px;
        text-align: center;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      .drop-menu {
        float: left;
        width: 20px;
        text-align: center;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        position: relative;
        ul {
          position: absolute;
          right: 0;
          bottom: 33px;
          width: 170px;
          background: #fff;
          border-radius: 3px;
          list-style: none;
          border: 1px solid #eee;
          li {
            color: #333;
            text-align: left;
            padding: 0 10px;
            cursor: pointer;
            span {
              display: inline-block;
              width: 10px;
            }
            &:hover {
              background: #efefef;
            }
          }
        }
      }
    }
  }
}
.chat-disabled,
.chat-black {
  left: 0;
  right: 0;
  bottom: 0;
  height: 99px;
  font-size: 16px;
  border-top: 1px solid #ededed;
  position: relative;
  line-height: 99px;
  text-align: center;
  a {
    color: #007aff;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
