<template>
  <div class="chat-list" ref="chatListRef">
    <div id="chatWrapper" class="list-wrapper">
      <div
        id="getHistory"
        class="list-history"
        :style="chatData.length && { visibility: 'visible' }"
        @click="getConversationMessagePage"
      >
        {{
          chatData.length === messageTotalSize ? "没有更多了" : "加载历史消息"
        }}
      </div>
      <div>
        <div id="customerNick" class="notice">
          客服 {{ customerName }} 为您服务
        </div>
      </div>
      <div id="message">
        <div
          class="msg-box"
          :class="{ client: item.userType === 'CLIENT' }"
          v-for="item of chatData"
          :key="item.id"
        >
          <div class="avatar">
            <img
              :src="
                item.userType === 'CLIENT'
                  ? require('../assets/img/avatar.png')
                  : item.customerImgUrl
              "
              alt=""
            />
          </div>
          <div class="name">
            {{
              item.userType === "CLIENT" ? clientName : item.customerUsername
            }}
            <span class="date">
              {{ item.updateTime }}
            </span>
          </div>

          <!-- 文字 -->
          <div
            class="bubble"
            :style="
              item.userType === 'CLIENT' && {
                color: '#fff',
                background: backgroundColor,
              }
            "
            v-if="item.messageType === 'TEXT'"
          >
            <img
              class="loading"
              src="../assets/img/loading.gif"
              style="display: none"
              alt=""
            />
            <div
              class="text"
              style="white-space: pre-wrap"
              v-html="xss(item.content)"
            />
          </div>

          <!-- 表情 -->
          <div
            class="bubble"
            style="background: #fcfcfc"
            v-if="item.messageType === 'EMOTICON'"
          >
            <img
              class="loading"
              src="../assets/img/loading.gif"
              style="display: none"
              alt=""
            />
            <img class="emotion" :src="getEmoticon(item.content)" alt="" />
          </div>

          <!-- 图片 -->
          <div class="image" v-if="item.messageType === 'PICTURE'">
            <!--            <img class="loading" src="../assets/img/loading.gif" alt="" />-->
            <img
              class="mask"
              src="../assets/img/photo-sending.png"
              style="display: none"
              alt=""
            />
            <img v-clickBlock class="img" :src="item.content" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, nextTick, ref, watch } from "vue";

export default {
  emits: ["get-new"],
  setup(props, context) {
    let api = inject("api");
    let store = inject("store");
    let xss = inject("xss");

    // 聊天列表数据
    const chatData = computed(() => {
      let copy = [...store.state.chatData];
      return copy.sort((n1, n2) => n1.id - n2.id);
    });
    // 聊天列表容器
    const chatListRef = ref(null);
    window.onresize = () => {
      chatListRef.value.scrollTop =
        chatListRef.value.scrollHeight - chatListRef.value.offsetHeight;
    };

    const messageTotalSize = ref(0);
    const customerName = computed(() => {
      return store.state.customer.username;
    });
    const clientName = ref(store.state.client.username);
    const backgroundColor = computed(() => {
      return store.state.config.style.backgroundColor;
    });

    let messagePageNum = ref(0);
    let messagePageSize = ref(20);
    getConversationMessagePage();
    // 获取聊天记录
    function getConversationMessagePage() {
      messagePageNum.value = messagePageNum.value + 1;
      api
        .getConversationMessagePage({
          pageNum: messagePageNum.value,
          pageSize: messagePageSize.value,
        })
        .then((data) => {
          messageTotalSize.value = data.content.totalSize;
          parserMsg(data.content.records);
        });
    }

    // 获取表情路径
    function getEmoticon(index) {
      return require("../assets/img/emotion/" + index + ".gif");
    }

    // 解析消息
    function parserMsg(messages) {
      for (let message of messages) {
        store.action.updateChatData(message);
      }
    }

    watch(
      chatData,
      async (chatList, prevChatList) => {
        await nextTick();
        let scrollBox = chatListRef.value;
        // 如果是加载历史消息需要滚动到顶部
        if (prevChatList[0] && chatList[0].id !== prevChatList[0].id) {
          scrollBox.scrollTop = 0;
        } else {
          // 默认
          scrollBox.scrollTop = scrollBox.scrollHeight - scrollBox.offsetHeight;
        }
      },
      {
        deep: true,
      }
    );
    return {
      chatData,
      chatListRef,
      messageTotalSize,
      customerName,
      clientName,
      backgroundColor,
      xss,
      getEmoticon,
      getConversationMessagePage,
    };
  },
};
</script>

<style lang="scss" scoped>
.fullscreen {
  .chat-list {
    height: calc(100vh - 175px);
  }
}

.mobile {
  .chat-list {
    height: calc(100vh - 102px);

    #customerNick {
      padding-bottom: 20px;
    }
  }

  .msg-box {
    padding-left: 50px;
  }

  &.chathabit-right {
    .chat-list {
      .list-wrapper {
        .msg-box {
          &.client {
            padding-right: 50px;
          }
        }
      }
    }
  }
}

.style1 {
  .chat-list {
    height: calc(100vh - 175px);
  }

  &.chathabit-left {
    .msg-box {
      padding-left: 50px;
    }
  }
}

.style2 {
  .avatar {
    display: none;
  }
}

.chathabit-right {
  &.style1 {
    .chat-list {
      .list-wrapper {
        .msg-box {
          padding-left: 50px;

          &.client {
            padding-right: 50px;

            .image {
              margin-left: 50px;
              margin-top: 10px;
            }

            .avatar {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }
  }

  .chat-list {
    .list-wrapper {
      .client {
        .name {
          text-align: right;
        }

        .bubble {
          float: right;
        }
      }

      .msg-box {
        &.client {
          .image {
            float: right;
            margin-top: 10px;
          }

          .avatar {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}

.chat-list {
  height: 365px;
  background: #fcfcfc;
  overflow: auto;

  #customerNick {
    text-align: center;
  }

  .list-wrapper {
    padding: 14px;
    text-align: left;
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 14px;
    color: #b2b2b2;

    .list-history {
      color: #aaa;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      visibility: hidden;
    }

    .msg-box {
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;

      &.chathabit-left {
        padding-left: 50px;
      }

      .avatar {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        margin-bottom: 2px;
        margin-left: 2px;
        font-size: 12px;
        line-height: 14px;
        color: #b2b2b2;

        .date {
          margin-left: 2px;
        }
      }

      .notice {
        color: #aaa;
        text-align: center;
        display: block;
        line-height: 30px;
      }

      .bubble {
        float: left;
        width: auto;
        color: #000;
        background-color: #fff;
        border: 1px solid #f0f0f0;
        position: relative;
        border-radius: 3px;
        padding: 8px 12px;
        font-size: 12px;
        line-height: 26px;
        word-wrap: break-word;
        max-width: 70%;
        min-height: 20px;

        .emotion {
        }

        .text {
          img {
            max-width: 400px;
          }
        }
      }

      .image {
        float: left;
        max-width: 100%;
        min-height: 40px;

        img {
          max-width: 100%;
          max-height: 350px;
          cursor: pointer;
        }
      }

      &.client {
        .name.chathabit-right {
          text-align: right;
        }

        .bubble {
          color: #fff;
          border: none;
          position: relative;
          font-size: 12px;

          &.chathabit-right {
            margin-left: 35px;
            float: right;
          }

          &.chat-habit2 {
            margin-right: 35px;
            float: left;
          }

          .loading {
            width: 16px;
            height: 16px;
            position: absolute;
            left: -22px;
            top: 10px;
          }
        }

        .image {
          position: relative;
          min-height: 40px;

          &.chathabit-right {
            float: right;
            margin-left: 35px;
          }

          &.chat-habit2 {
            float: left;
            margin-right: 35px;
          }

          .loading {
            width: 16px;
            height: 16px;
            position: absolute;
            left: -22px;
            top: 10px;
          }
        }
      }
    }
  }
}
</style>
