import Fingerprint2 from "fingerprintjs2";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const fpPromise = FingerprintJS.load();

const util = {};

/**获取随机id */
util.getRandom = function () {
  return (
    (Math.random() * 1000000000).toString(16).substr(0, 6) +
    new Date().getTime() +
    Math.random().toString().substr(2, 5)
  );
};

/**获取参数 */
util.getQuery = function () {
  let query = {};
  if (location.search) {
    let str = location.search.substr(1);
    let arr = str.split("&"),
      newArr = [];
    for (let i = 0; i < arr.length; i++) {
      newArr = arr[i].split("=");
      if (newArr[0] !== undefined) {
        query[newArr[0]] = newArr[1];
      }
    }
  }
  return query;
};

/**获取终端类型 */
util.getSource = function () {
  let ua = navigator.userAgent.toLowerCase();
  if (
    ua.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return "H5";
  }
  return "PC";
};

/**测试mime */
util._mime = function (option, value) {
  let mimeTypes = navigator.mimeTypes;
  for (let mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true;
    }
  }
  return false;
};

/**获取浏览器信息 */
util.getBrowserInfo = function () {
  let ua = navigator.userAgent.toLowerCase();
  let browser = null;
  let browserArray = [
    "IE",
    "IE11",
    "IE10",
    "IE9",
    "IE8",
    "Safari",
    "Chrome",
    "Firefox",
    "Opera",
    "Edge",
    "QQBrowser",
    "QQ",
    "WeChat",
    "Baidu",
    "Sougo",
    "Maxthon",
    "UC",
    "Qihu360",
  ];
  let OptionArray = [
    Boolean(window.ActiveXObject || "ActiveXObject" in window),
    ua.indexOf("trident") > -1 && ua.indexOf("rv:11.0") > -1,
    ua.indexOf("msie 10.0") > -1,
    ua.indexOf("msie 9.0") > -1,
    ua.indexOf("msie 8.0") > -1,
    ua.indexOf("safari") > -1 && ua.indexOf("chrome") === -1,
    ua.indexOf("chrome") > -1 && ua.indexOf("safari") > -1,
    ua.indexOf("firefox") > -1,
    ua.indexOf("opr") > -1,
    ua.indexOf("edge") > -1,
    ua.indexOf("qqbrowser") > -1,
    ua.indexOf("qq") > -1,
    ua.indexOf("micromessenger") > -1,
    ua.indexOf("bidubrowser") > -1,
    ua.indexOf("metasr") > -1,
    ua.indexOf("maxthon") > -1,
    ua.indexOf(" ubrowser") > -1,
    util._mime("type", "application/vnd.chromium.remoting-viewer"),
  ];
  for (let i = 0; i < browserArray.length; i++) {
    if (OptionArray[i]) {
      browser = browserArray[i];
    }
  }
  return browser;
};

/**获取浏览器指纹 */
util.getFingerprint = async function () {
  const fp = await fpPromise;
  const result = await fp.get();

  // This is the visitor identifier:
  return result.visitorId;
};
// util.createFingerprint = async function () {
//   let values = await Fingerprint2.getPromise();
//   values.push({
//     key: "ip",
//     value: returnCitySN["cip"], //通过接口获取的到ip
//   });
//   return Fingerprint2.x64hash128(values.join(""), 31);
// };
/**获取系统信息 */
util.getSystemInfo = function () {
  let OS = null;
  let OSArray = {};
  let UserAgent = navigator.userAgent.toLowerCase();
  OSArray.Windows = UserAgent.indexOf("windows") > -1;
  OSArray.WindowsXP = UserAgent.indexOf("windows nt 5") > -1;
  OSArray.Windows7 = UserAgent.indexOf("windows nt 7") > -1;
  OSArray.Windows10 = UserAgent.indexOf("windows nt 10") > -1;
  OSArray.Mac =
    navigator.platform === "Mac68K" ||
    navigator.platform === "MacPPC" ||
    navigator.platform === "Macintosh" ||
    navigator.platform === "MacIntel";
  OSArray.IOS =
    UserAgent.indexOf("iphone") > -1 ||
    UserAgent.indexOf("ipod") > -1 ||
    UserAgent.indexOf("ipad") > -1;
  OSArray.Android = UserAgent.indexOf("android") > -1;
  for (let i in OSArray) {
    if (OSArray[i]) {
      OS = i;
    }
  }
  return OS;
};

/**HTML转义 */
util.unescape = function (html) {
  if (html.indexOf("<img") !== -1 && html.indexOf("src=") !== -1) {
    return html;
  }
  let reg =
    /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  return html.replace(reg, function ($1) {
    return (
      "<a href='" +
      $1.toLowerCase() +
      "' target='_blank'>" +
      $1.toLowerCase() +
      "</a>"
    );
  });
};

/**过滤内容 */
util.countSubstr = function (str, substr, isIgnore) {
  let count;
  let reg;
  if (isIgnore === true) {
    reg = "/" + substr + "/gi";
  } else {
    reg = "/" + substr + "/g";
  }
  reg = eval(reg);
  if (str.match(reg) === null) {
    count = 0;
  } else {
    count = str.match(reg).length;
  }
  return count;
};

/**获取时间 */
util.getTimestamp = function (timeString) {
  let now = new Date().Format("M月d日");
  let yesterday = new Date();
  yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000);
  yesterday = yesterday.Format("M月d日");
  timeString = timeString.replace(new RegExp(now, "g"), "");
  timeString = timeString.replace(new RegExp(yesterday, "g"), "昨天");
  return timeString;
};

/**防抖 */
util.deBounce = function (func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

/**关闭窗口 */
util.closeWin = function () {
  if (
    navigator.userAgent.indexOf("Firefox") !== -1 ||
    navigator.userAgent.indexOf("Chrome") !== -1
  ) {
    window.location.href = "about:blank";
    window.close();
  } else {
    window.opener = null;
    window.open("", "_self");
    window.close();
  }
};

/**过滤消息内容 */
/* util.replaceSrc = function(txt) {
  var result = txt.replace(/<\/?[^>]*>/g, "").replace(/[ | ]*\n/g, "\n"); //拦截恶意输入html标签
  var reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/gi;
  result = result.replace(reg, function(item) {
    return "<a href='" + item + "' target='_blank'>" + item + "</a>";
  });
  result = result.replace(/\n/g, "<br />"); //换行符
  return result;
}; */

if (!Array.prototype.map) {
  Array.prototype.map = function (callback, thisArg) {
    let T, A, k;

    if (this == null) {
      throw new TypeError(" this is null or not defined");
    }

    // 1. Let O be the result of calling ToObject passing the |this| value as the argument.
    let O = Object(this);

    // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
    // 3. Let len be ToUint32(lenValue).
    let len = O.length >>> 0;

    // 4. If IsCallable(callback) is false, throw a TypeError exception.
    // See: http://es5.github.com/#x9.11
    if (typeof callback !== "function") {
      throw new TypeError(callback + " is not a function");
    }

    // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
    if (thisArg) {
      T = thisArg;
    }

    // 6. Let A be a new array created as if by the expression new Array(len) where Array is
    // the standard built-in constructor with that name and len is the value of len.
    A = new Array(len);

    // 7. Let k be 0
    k = 0;

    // 8. Repeat, while k < len
    while (k < len) {
      let kValue, mappedValue;

      // a. Let Pk be ToString(k).
      //   This is implicit for LHS operands of the in operator
      // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
      //   This step can be combined with c
      // c. If kPresent is true, then
      if (k in O) {
        // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
        kValue = O[k];

        // ii. Let mappedValue be the result of calling the Call internal method of callback
        // with T as the this value and argument list containing kValue, k, and O.
        mappedValue = callback.call(T, kValue, k, O);

        // iii. Call the DefineOwnProperty internal method of A with arguments
        // Pk, Property Descriptor {Value: mappedValue, : true, Enumerable: true, Configurable: true},
        // and false.

        // In browsers that support Object.defineProperty, use the following:
        // Object.defineProperty(A, Pk, { value: mappedValue, writable: true, enumerable: true, configurable: true });

        // For best browser support, use the following:
        A[k] = mappedValue;
      }
      // d. Increase k by 1.
      k++;
    }

    // 9. return A
    return A;
  };
}

Date.prototype.Format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    S: this.getMilliseconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

//注册indexOf
if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (elt /*, from*/) {
    let len = this.length >>> 0;
    let from = Number(arguments[1]) || 0;
    from = from < 0 ? Math.ceil(from) : Math.floor(from);
    if (from < 0) {
      from += len;
    }
    for (; from < len; from++) {
      if (from in this && this[from] === elt) {
        return from;
      }
    }
    return -1;
  };
}

/**支持IE10以下bind */
if (!Function.prototype.bind) {
  Function.prototype.bind = function (oThis) {
    if (typeof this !== "function") {
      throw new TypeError(
        "Function.prototype.bind - what is trying to be bound is not callable"
      );
    }
    let aArgs = Array.prototype.slice.call(arguments, 1),
      fToBind = this,
      fNOP = function () {},
      fBound = function () {
        return fToBind.apply(
          this instanceof fNOP && oThis ? this : oThis,
          aArgs.concat(Array.prototype.slice.call(arguments))
        );
      };
    fNOP.prototype = this.prototype;
    fBound.prototype = new fNOP();
    return fBound;
  };
}

let toastTimer;
/* 弹出提示 */
util.toast = (message, cb) => {
  if (document.querySelector("body").querySelector(".bounced")) {
    clearTimeout(toastTimer);
    let dom = document.querySelector("body").querySelector(".bounced");
    document.body.removeChild(dom);
  }
  let bounced = document.createElement("div");
  bounced.setAttribute("class", "bounced");
  document.body.appendChild(bounced);
  setTimeout(() => {
    bounced.style.transform = "scale(1) translate3d(-50%, -50%, 0)";
    bounced.style.opacity = 1;
  }, 50);
  bounced.innerHTML = message;
  toastTimer = setTimeout(() => {
    bounced.style.transform = "scale(0.5) translate3d(-50%, -50%, 0)";
    bounced.style.opacity = 0;
    setTimeout(() => {
      document.body.removeChild(bounced);
    }, 150);
  }, 3000);
  cb &&
    setTimeout(() => {
      cb();
    }, 3000);
};

/* 上传图片 */
util.uploadImg = function (e, cb) {
  let files = e.target.files,
    file;
  if (!files || files.length === 0) {
    return;
  }
  file = files[0];
  let fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = function (e) {
    let format = [
      "image/jpg",
      "image/png",
      "image/gif",
      "image/jpeg",
      "image/x-png",
      "image/pjpeg",
    ];
    if (format.indexOf(file.type) === -1) {
      util.toast("暂不支持此格式");
      return;
    }
    if (file.size / 1024 / 1024 > 7) {
      util.toast("图片大于7MB无法发送");
      return;
    }
    let src = e.target.result;
    //开始图片压缩
    let img = new Image();
    img.src = src;
    img.onload = function () {
      let width = img.width;
      let height = img.height;
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let anw = document.createAttribute("width");
      anw.nodeValue = width;
      let anh = document.createAttribute("height");
      anh.nodeValue = height;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.fillStyle = "#fff";
      ctx.drawImage(img, 0, 0, width, height);
      let imgData = canvas.toDataURL("image/jpeg", 1);
      let data = {
        fileName: file.name,
        fileBase64: imgData ? imgData : src,
      };
      cb(data);
    };
  };
};

util.playAudio = function (type) {
  switch (type) {
    case "NEW_MESSAGE":
      // eslint-disable-next-line no-case-declarations
      let audioN = document.createElement("audio");
      audioN.src = "/public/music/new-message.mp3";
      audioN.style.display = "none";
      // eslint-disable-next-line no-case-declarations
      let playPromiseNew = audioN.play();
      if (playPromiseNew) {
        playPromiseNew
          .then(function () {
            // 音频加载成功
            // 音频的播放需要耗时
            setTimeout(function () {
              // 后续操作
            }, audioN.duration * 1000); // audio.duration 为音频的时长单位为秒
          })
          .catch(function () {
            // 音频加载失败
            audioN.pause();
          });
      }
      break;
    case "SEND_MESSAGE":
      // eslint-disable-next-line no-case-declarations
      let audioS = document.createElement("audio");
      audioS.src = "/public/music/sent-message.mp3";
      audioS.style.display = "none";
      // eslint-disable-next-line no-case-declarations
      let playPromiseSend = audioS.play();
      if (playPromiseSend) {
        playPromiseSend
          .then(function () {
            // 音频加载成功
            // 音频的播放需要耗时
            setTimeout(function () {
              // 后续操作
            }, audioS.duration * 1000); // audio.duration 为音频的时长单位为秒
          })
          .catch(function () {
            // 音频加载失败
            audioS.pause();
          });
      }
  }
};

export default util;
