<template>
  <div class="chat-adbox">
    <a :href="advertisementLink" target="_blank">
      <img :src="advertisementImg" alt="客服人员" />
    </a>
  </div>
</template>

<script>
import { ref, inject } from "vue";
export default {
  setup() {
    let store = inject("store");

    const advertisementImg = ref(store.state.config.style.advertisementImg);
    const advertisementLink = ref(store.state.config.style.advertisementLink);
    return {
      advertisementImg,
      advertisementLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.fullscreen,
.style1 {
  .chat-adbox {
    height: auto;
  }
}
.mobile {
  .chat-adbox {
    display: none;
  }
}
.chat-adbox {
  width: 186px;
  height: 460px;
  padding: 10px;
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;
  img {
    width: 168px;
  }
}
</style>
