import axios from "./request";
import util from "../util";

/**
 * 获取配置
 */
const preStartConversation = ({
  device = util.getSource(),
  fingerprint = null,
} = {}) =>
  axios.post("/user/preStartConversation", {
    device,
    fingerprint,
  });
/**
 * 开始会话
 */
const startConversation = ({
  source = null,
  preQuestion = null,
  groupId = null,
} = {}) =>
  axios.post("/user/startConversation", {
    source,
    preQuestion,
    groupId,
  });
/**
 * 获取聊天消息
 */
const getConversationMessagePage = ({ pageNum = 1, pageSize = 20 } = {}) =>
  axios.post("/user/getConversationMessagePage", {
    pageNum,
    pageSize,
  });
/**
 * 发送正在输入的消息
 */
const sendInputMessage = ({ message }) =>
  axios.post("/user/sendInputMessage", {
    message,
  });
/**
 * 发送消息
 */
const sendMessage = ({ messageType, message }) =>
  axios.post("/user/sendMessage", {
    messageType,
    message,
  });
/**
 * 获取评价配置
 */
const getConversationEvaluationList = () =>
  axios.post("/user/getConversationEvaluationList", {});
/**
 * 提交评价
 */
const submitConversationEvaluation = ({
  conversationEvaluationId,
  conversationEvaluationRemark,
}) =>
  axios.post("/user/submitConversationEvaluation", {
    conversationEvaluationId,
    conversationEvaluationRemark,
  });
/**
 * 提交评价
 */
const submitWorkflow = ({ title, description }) =>
  axios.post("/user/submitWorkflow", {
    title,
    description,
  });
/**
 * 校验客户
 */
const validClient = ({ groupId, username }) =>
  axios.post("/user/validClient", {
    groupId,
    username,
  });
export default {
  preStartConversation,
  startConversation,
  getConversationMessagePage,
  sendInputMessage,
  sendMessage,
  getConversationEvaluationList,
  submitConversationEvaluation,
  submitWorkflow,
  validClient,
};
