import { reactive } from "vue";

export const createState = () =>
  reactive({
    //商户
    merchant: {
      merchantCode: "",
      token: "",
    },
    //会话
    conversation: {
      id: null,
    },
    //客户
    client: {
      id: null,
      username: null,
    },
    //客服
    customer: {
      id: null,
      username: null,
      imgUrl: require("../assets/img/avatar.png"),
    },
    //配置
    config: {
      setting: {
        evaluationFlag: "", //评价开关
        responseFlag: "", //智能回复开关
      },
      style: {
        device: "", //设备 PC/H5
        styleType: "", //样式类型 MODEL_1/MODEL_2
        chatHabit: "", //聊天习惯 RIGHT/LEFT
        welcomeMessage: "", //欢迎语
        backgroundColor: "#5749C1", //背景颜色
        backgroundImg: "", //背景图片
        logoImg: "", //弹出框左上角图片
        advertisementFlag: "", //广告栏开关
        advertisementPosition: "", //广告栏位置 RIGHT/LEFT
        advertisementImg: "", //广告栏图片
        advertisementLink: "", //广告栏地址
        preFlag: "", //询前开关
        preBackgroundColor: "", //询前背景颜色
        preBackgroundImg: "", //询前背景图片
        preMessage: "", //询前欢迎语
        preAdvertisementImg: "", //询前广告图片
        preAdvertisementLink: "", //询前广告地址
        preQuestion: "", //询前问题集
        leaveBackgroundColor: "", //留言背景颜色
        leaveBackgroundImg: "", //留言背景图片
        leaveMessage: "", //留言欢迎语
      },
    },
    //输入的聊天数据
    chatData: [],
    //对话状态 0:正常 1:打开留言 2:打开评价 3:结束会话 4:拉黑 5:排队等待
    chatState: 0,
    websocket: null,
    errorMsg: "系统暂不可用",
  });
