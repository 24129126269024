<template>
  <div class="chat-prev" :class="media">
    <div class="header" :style="{ backgroundColor: backgroundColor }">
      <strong>询前表单</strong>
    </div>
    <div>
      <img :src="preAdvertisementImg" alt="" />
    </div>
    <div v-text="preMessage"></div>
    <div class="md-list">
      <ul>
        <li>
          <div>
            <div class="form_single_choice">
              <div class="form-title">询前问题集</div>
              <div>
                <ul>
                  <li
                    v-for="(item, index) of preGuideQuestionList"
                    :key="index"
                    class="choice_list"
                  >
                    <label @click="radioChange(item)">
                      <div class="radio_wrapper">
                        <input type="radio" :checked="item === itemChecked" />
                      </div>
                      <div>
                        {{ item }}
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="submit-preGuide">
      <button
        @click="submit"
        class="btn-submit"
        :style="{ backgroundColor: backgroundColor }"
      >
        开始聊天
      </button>
    </div>
  </div>
</template>

<script>
import { ref, reactive, inject } from "vue";
export default {
  setup(props, context) {
    let store = inject("store");
    let util = inject("util");

    const media = ref(util.getSource());
    const backgroundColor = ref(store.state.config.style.preBackgroundColor);
    const preMessage = ref(store.state.config.style.preMessage);
    const preAdvertisementImg = ref(
      store.state.config.style.preAdvertisementImg
    );
    const preGuideQuestionList = reactive(
      JSON.parse(store.state.config.style.preQuestion || "[]")
    );
    const itemChecked = ref(null);
    if (preGuideQuestionList.length > 1) {
      itemChecked.value = preGuideQuestionList[0];
    }
    window.onresize = () => {
      media.value = util.getSource();
    };
    function radioChange(item) {
      itemChecked.value = item;
    }
    function submit() {
      context.emit("startConversation", { preQuestion: itemChecked.value });
    }
    return {
      media,
      backgroundColor,
      preMessage,
      preAdvertisementImg,
      preGuideQuestionList,
      itemChecked,
      radioChange,
      submit,
    };
  },
};
</script>

<style scoped lang="scss">
.chat-prev {
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
  background: #f9fafc;
  width: 40%;
  margin: 100px auto;
  &.mobile {
    width: auto;
    margin: 0;
  }
  .header {
    background: #af9c0e;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    color: #515a6e;
  }
  .md-list {
    ul {
      li {
        padding-top: 12px;
        .form_text {
          display: flex;
          padding: 0 12px;
          border: 1px solid #eff0f2;
          height: 36px;
          align-items: center;
          > div {
            height: 32px;
            line-height: 32px;
            &.form-title {
              padding: 4px 7px;
              font-size: inherit;
              font-weight: 400;
              line-height: 2;
              color: #515a6e;
              text-align: center;
              background-color: #f8f8f9;
              border: 1px solid #dcdee2;
              box-sizing: border-box;
              border-radius: 4px 0 0 4px;
              vertical-align: middle;
              border-right: 0;
              white-space: nowrap;
            }
          }
          div:nth-child(2) {
            width: 100%;
            input {
              display: inline-block;
              width: 100%;
              height: 32px;
              line-height: 1.5;
              padding: 4px 7px;
              font-size: 12px;
              border: 1px solid #dcdee2;
              color: #515a6e;
              background-color: #fff;
              background-image: none;
              position: relative;
              cursor: text;
              box-sizing: border-box;
              border-radius: 0 4px 4px 0;
              transition: border ease-in-out, box-shadow 0.3s;
            }
            .ivu-input:hover {
              border-color: #57a3f3;
            }
            .ivu-input:focus {
              outline: none;
              border-color: #57a3f3;
              box-shadow: 0 0 0 2px rgb(45 140 240 / 20%);
            }
          }
        }
        .form_single_choice {
          .form-title,
          .choice_list {
            padding: 0 12px;
            background-color: #f9fafc;
            border: 1px solid #eff0f2;
            height: 36px;
            line-height: 36px;
          }
          .form-title {
            text-align: initial;
            background-color: #d4d0d0;
          }
        }
      }
    }
  }

  .choice_list {
    label {
      cursor: pointer;
      display: flex;
      line-height: 1px;
      height: 100%;
      align-items: center;
    }
  }
  .error-tip {
    color: red;
  }
  .submit-preGuide {
    text-align: center;
    padding: 24px 12px 48px;
    .btn-submit {
      padding: 10px 0;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      border: none;
      outline: none;
      background: #af9c0e;
      color: white;
    }
  }
}
</style>
