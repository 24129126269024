<template>
  <div class="chat-header" :style="{ 'background-color': backgroundColor }">
    <div class="customer">
      <div class="customer-img">
        <img :src="customerImgUrl" alt="客服人员" />
      </div>
      <div class="customer-welcome">
        <div class="customer-nick">{{ customerName }}</div>
        <div class="">{{ welcomeMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed } from "vue";
export default {
  setup() {
    let store = inject("store");

    const backgroundColor = computed(() => {
      return store.state.config.style.backgroundColor;
    });
    const customerImgUrl = computed(() => {
      return store.state.customer.imgUrl;
    });
    const customerName = computed(() => {
      return store.state.customer.username;
    });
    const welcomeMessage = computed(() => {
      return store.state.config.style.welcomeMessage;
    });
    return {
      backgroundColor,
      customerImgUrl,
      customerName,
      welcomeMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-header {
  height: 60px;
  padding: 0 15px;
}
.chat-header .customer {
  text-align: left;
  color: #fff;
}
.chat-header .customerIcon {
  width: 60px;
  height: 60px;
  float: right;
}
.chat-header .customer .customer-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin: 5px 0;
}
.chat-header .customer .customer-img img {
  width: 100%;
  height: 100%;
}
.chat-header .customerIcon .customerIconImg {
  height: 40px;
  padding: 10px;
}
.chat-header .customerIcon .customerIconImg .icon-img {
  height: 44px;
  width: 44px;
}
.chat-header .customer .customer-welcome {
  .customer-nick {
    padding-top: 8px;
  }
  width: 120px;
  height: 55px;
  float: left;
  padding: 5px 0 0 6px;
}
</style>
