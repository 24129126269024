// 更新TOKEN
const updateToken = (state) => (token) => {
  state.merchant.token = token;
};
// 更新商户码
const updateMerchantCode = (state) => (merchantCode) => {
  state.merchant.merchantCode = merchantCode;
};
// 更新配置文件
const updateConfig = (state) => (config) => {
  state.config = { ...state.config, ...config };
};
// 更新用户
const updateUser = (state) => (data) => {
  state.conversation.id = data.conversationId;
  state.client.id = data.clientId;
  state.client.username = data.clientName;
  state.customer.id = data.customerId;
  state.customer.username = data.customerName;
  state.customer.imgUrl = data.customerImgUrl;
};
// 更新聊天数据
const updateChatData = (state) => (chatData) => {
  let findIndex = chatData.length - 1;
  const findChat = state.chatData.find((c, index) => {
    findIndex = index;
    return c.id === chatData.id;
  });
  if (!findChat) {
    state.chatData.splice(state.chatData.length, 0, chatData);
  }
};

// 更新对话状态
const updateChatState = (state) => (code) => {
  state.chatState = code;
};
// 更新Websocket
const updateWebsocket = (state) => (websocket) => {
  state.websocket = websocket;
};

// 更新错误信息
const updateErrorMsg = (state) => (errorMsg) => {
  state.errorMsg = errorMsg;
};
export const createAction = (state) => ({
  updateMerchantCode: updateMerchantCode(state),
  updateToken: updateToken(state),
  updateConfig: updateConfig(state),
  updateUser: updateUser(state),
  updateChatData: updateChatData(state),
  updateChatState: updateChatState(state),
  updateWebsocket: updateWebsocket(state),
  updateErrorMsg:updateErrorMsg(state),
});
