<template>
  <router-view />
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    // window.location.replace("https://148.66.62.218/chat/index.html?id=200000")
    let router = useRouter();
    process.env.NODE_ENV === "development" &&
      router.replace({
        path: "/",
        query: {
          merchantCode: "1000",
          groupId: 4,
          token: "510fa55d-fe97-41ec-8cb0-3b9ccffc72eb",
          markValidUsername: true,
        },
      });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.drag-area {
  cursor: move;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 30%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0; */
  transition: all linear 0.2s;
}
.layer {
  position: absolute;
  box-shadow: 2px 0 50px rgb(0 0 0 / 30%);
  margin: 0;
  padding: 0;
  background-color: #fff;
  -webkit-background-clip: content;
  transform: scale(0);
  transition: all ease-in-out 0.25s;
  max-width: 90%;
  img {
    max-width: 100%;
  }
}
.shut {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  margin-left: 0;
  background: url("./assets/img/icon.png") -149px -31px;
  cursor: pointer;
}
.shut:hover {
  background-position: -180px -31px;
}
.bounced {
  position: fixed;
  z-index: 999999;
  left: 50%;
  top: 50%;
  transform-origin: center center;
  background-color: #00000099;
  padding: 10px 25px;
  font-size: 14px;
  color: white;
  border-radius: 3px;
  transform: scale(0.5);
  opacity: 0;
  transition: all ease-in-out 0.15s;
}
</style>
