import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/icon.css";
import "./assets/css/reset.css";

import xss from "xss";
import api from "./api";
import util from "./util";
import { useStore } from "./store";
const app = createApp(App);

app.directive("drag", (el, binding) => {
  el.classList.add("drag-area");
  let moveDOM = binding.value;
  if (moveDOM === undefined) {
    moveDOM = el;
  }
  if (moveDOM) {
    moveDOM.style.position = "fixed";
    moveDOM.style.left = moveDOM.offsetLeft + "px";
    moveDOM.style.top = moveDOM.offsetTop + "px";
  }
  el.onmousedown = function (e) {
    const clientX = e.clientX;
    const clientY = e.clientY;
    let offsetLeft = moveDOM.offsetLeft;
    let offsetTop = moveDOM.offsetTop;
    document.onmousemove = function (e) {
      let left = e.clientX - clientX + offsetLeft;
      let top = e.clientY - clientY + offsetTop;
      if (left < 0) {
        // 左右边界
        left = 0;
      }
      if (left + moveDOM.offsetWidth > window.innerWidth) {
        left = window.innerWidth - moveDOM.offsetWidth;
      }
      if (top < 0) {
        // 左右边界
        top = 0;
      }
      if (top + moveDOM.offsetHeight > window.innerHeight) {
        top = window.innerHeight - moveDOM.offsetHeight;
      }
      moveDOM.style.left = left + "px";
      moveDOM.style.top = top + "px";
    };
    document.onmouseup = function () {
      document.onmousemove = null;
    };
    return false;
  };
});

app.directive("clickBlock", (el) => {
  el.onclick = function () {
    let mask = document.createElement("div");
    let layer = document.createElement("div");
    let shut = document.createElement("div");
    let CopyDom = el.cloneNode(false);
    mask.setAttribute("class", "mask");
    layer.setAttribute("class", "layer");
    shut.setAttribute("class", "shut");
    document.body.appendChild(mask);
    mask.appendChild(layer);
    layer.appendChild(shut);
    layer.appendChild(CopyDom);
    setTimeout(() => {
      layer.style.transform = "scale(1)";
      mask.style.opacity = 1;
    }, 50);
    CopyDom.style.maxHeight = 600 + "px";
    shut.onclick = function () {
      mask.style.opacity = 0;
      layer.style.transform = "scale(0)";
      setTimeout(() => {
        document.body.removeChild(mask);
      }, 250);
    };
  };
});
const store = useStore();
app.provide("api", api);
app.provide("util", util);
app.provide("store", store);
app.provide("xss", xss);
app.use(router).mount("#app");
