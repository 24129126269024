<template>
  <div>
    <ChatPrev v-if="preFlag" @startConversation="startConversation" />
    <div
      class="chat"
      :class="[style, chatHabit, { fullscreen: fullScreen }]"
      v-else
    >
      <ChatHeader />
      <div
        class="chat-body"
        :style="
          advertisementPosition === 'RIGHT' && {
            'flex-direction': 'row-reverse',
          }
        "
      >
        <ChatAdvert v-if="advertisementFlag" />
        <div>
          <ChatList @get-new="getConversationMessage" />
          <ChatInput :queue="queue" @on-input="sendMessage" />
        </div>
      </div>
    </div>
    <LeaveMessage v-if="chatState === 1" />
    <!--    <Valuation v-if="chatState === 2" />-->
    <ChatUser
      v-if="validClientFlag"
      :groupId="groupId"
      @processChat="processChat"
    />
  </div>
</template>

<script>
import ChatPrev from "@/components/ChatPrev";
import ChatHeader from "@/components/ChatHeader";
import ChatList from "@/components/ChatList";
import ChatAdvert from "@/components/ChatAdvert";
import ChatInput from "@/components/ChatInput";
import LeaveMessage from "@/components/LeaveMessage";
import ChatUser from "@/components/ChatUser";

import { computed, inject, ref } from "vue";
import { useRoute } from "vue-router";
import WsClient from "../api/WsClinet";
import PubSub from "pubsub-js";

export default {
  components: {
    ChatHeader,
    ChatList,
    ChatAdvert,
    ChatInput,
    ChatPrev,
    LeaveMessage,
    ChatUser,
  },
  setup() {
    let api = inject("api");
    let store = inject("store");
    let util = inject("util");
    let route = useRoute();

    const fullScreen = ref(false);
    const preFlag = ref(false);
    const style = ref("style1");
    const chatHabit = ref("RIGHT");
    const advertisementFlag = ref(false);
    const advertisementPosition = ref("RIGHT");
    const chatState = computed(() => {
      return store.state.chatState;
    });
    const queue = ref(null);
    // pc窗口onresize的时候设置聊天全屏
    window.onresize = () => {
      fullScreen.value = util.getSource() === "PC" && window.innerWidth <= 800;
    };

    // 设置TOKEN令牌
    store.action.updateToken(route.query.token);
    store.action.updateMerchantCode(route.query.merchantCode);

    const groupId = route.query.groupId;
    const validClientFlag = ref(false);

    const markValidUsername = route.query.markValidUsername;
    if (
      markValidUsername &&
      (markValidUsername === true || markValidUsername === "true")
    ) {
      validClientFlag.value = true;
    } else {
      processChat();
    }

    // processChat();
    function processChat() {
      validClientFlag.value = false;
      //获取Setting
      util.getFingerprint().then((fingerprint) => {
        api
          .preStartConversation({ fingerprint })
          .then((data) => {
            //设置缓存
            store.action.updateConfig(data.content);
            //初始化配置数据
            if (util.getSource() === "H5") {
              style.value = "mobile";
            } else {
              style.value =
                data.content.style.styleType === "MODEL_2"
                  ? "style2"
                  : "style1";
            }
            chatHabit.value =
              data.content.style.chatHabit === "LEFT"
                ? "chathabit-left"
                : "chathabit-right";
            advertisementFlag.value = data.content.style.advertisementFlag;
            advertisementPosition.value =
              data.content.style.advertisementPosition;
            if (data.content.style.preFlag) {
              preFlag.value = true;
            } else {
              startConversation({});
            }
          })
          .catch(() => {
            store.action.updateChatState(4);
          });
      });
    }

    function startConversation({
      groupId = route.query.groupId,
      source = route.query.source,
      preQuestion = null,
    }) {
      //socket连接
      init();
      api
        .startConversation({ source, preQuestion, groupId })
        .then((data) => {
          preFlag.value = false;
          if (data.content.queueCount) {
            store.action.updateChatState(5);
            queue.value = data.content.queueCount;
          } else {
            store.action.updateUser({
              ...data.content,
            });
          }
        })
        .catch((error) => {
          if (error.code === 202016) {
            //没有客服,打开留言
            store.action.updateChatState(1);
          }
        });
    }

    // 获取最新聊天
    function getConversationMessage() {
      api.getConversationMessagePage().then((data) => {
        for (let message of data.content.records) {
          store.action.updateChatData(message);
        }
      });
    }

    function sendMessage(data) {
      api
        .sendMessage(data)
        .then((data) => {
          store.action.updateChatData(data.content);
        })
        .catch(() => {
          getConversationMessage();
        });
    }

    function init() {
      let ws = new WsClient(
        store.state.merchant.merchantCode,
        store.state.merchant.token
      );
      ws.openWs();
      store.action.updateWebsocket(ws);

      PubSub.subscribe("openWs", (_, data) => {
        getConversationMessage();
        // }
      });
      PubSub.subscribe("newMessage", (_, data) => {
        //有延迟,第一个消息在startConversation之前返回
        // if (data.conversationId === store.state.conversation.id) {
        store.action.updateChatData(data);
        // }
      });
      PubSub.subscribe("newConversation", (_, data) => {
        if (data.id !== store.state.conversation.id) {
          store.action.updateUser({
            ...data,
            conversationId: data.id,
            customerName: data.customerUsername,
          });
          store.action.updateChatState(0);
        }
      });
      PubSub.subscribe("closeConversation", (_, data) => {
        if (data.id === store.state.conversation.id) {
          store.action.updateChatState(3);
        }
      });
    }

    return {
      fullScreen,
      preFlag,
      style,
      chatHabit,
      advertisementPosition,
      advertisementFlag,
      chatState,
      queue,
      groupId,
      validClientFlag,
      startConversation,
      getConversationMessage,
      sendMessage,
      processChat,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat {
  &.style2:not(.fullscreen) {
    width: 688px;
    height: 540px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -360px 0 0 -344px;
    border: 1px solid #f0f0f0;
  }

  background: #fff;

  .chat-body {
    display: flex;

    > div:last-child {
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
