import PubSub from "pubsub-js";

class WsClient {
  reopenTimes = 0;
  heartbeatInterval = null;

  constructor(merchantCode, token) {
    this.merchantCode = merchantCode;
    this.token = token;
    this.ws = null;
  }

  openWs() {
    this.ws = new WebSocket(
      `${process.env.VUE_APP_WEBSOCKET_HOST}/ws?USER_TYPE=CLIENT&MERCHANT_CODE=${this.merchantCode}&TOKEN=${this.token}`
    );
    this.ws.onopen = () => {
      console.log("ws已连接");
      PubSub.publish("openWs");
      this.reopenTimes = 0;
      this.heartbeatInterval = setInterval(() => {
        this.ws.send(JSON.stringify({ method: "HEARTBEAT", content: "心跳" }));
      }, 10000);
    };
    this.ws.onmessage = (event) => {
      const event_data = JSON.parse(event.data);
      switch (event_data.method) {
        case "CONVERSATION_MESSAGE_SEND_TO_CUSTOMER_AND_CLIENT":
          PubSub.publish("newMessage", JSON.parse(event_data.content));
          break;
        case "CONVERSATION_NEW_TO_CLIENT":
          PubSub.publish("newConversation", JSON.parse(event_data.content));
          break;
        case "CONVERSATION_CLOSE_TO_CUSTOMER_AND_CLIENT":
          PubSub.publish("closeConversation", JSON.parse(event_data.content));
          break;
      }
    };
    this.ws.onclose = (error) => {
      console.warn(error);
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
      }
      this.reOpen();
    };
    this.ws.onerror = (error) => {
      console.warn(error);
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
      }
      this.reOpen();
    };
  }

  reOpen() {
    console.log("调用ws reOpen");
    this.reopenTimes++;
    if (this.reopenTimes === 1) {
      this.openWs();
    } else if (this.reopenTimes >= 3) {
      console.log("网络错误");
    } else {
      console.log("WS已断开，5s后进行第" + this.reopenTimes + "次重连");
      const reOpenTimeout = setTimeout(() => {
        this.openWs();
        clearTimeout(reOpenTimeout);
      }, 5000);
    }
  }

  closeWs() {
    this.reopenTimes = 100;
    if (this.ws) {
      this.ws.close();
    }
    this.ws = null;
    clearInterval(this.heartbeatInterval);
  }
}

export default WsClient;
