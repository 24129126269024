import Axios from "axios";
import { v4 as uuidV4 } from "uuid";
import { useStore } from "../store";
const store = useStore();

const axios = Axios.create({
  baseURL: "/api",
  method: "post",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000,
});

axios.interceptors.request.use(
  (config) => {
    config.headers["TRACE-ID"] = uuidV4();
    if (store.state.merchant.token) {
      config.headers.AUTHORIZE = store.state.merchant.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (res.status === 200 && res.data.code === 200) {
      return res.data;
    } else {
      return Promise.reject(res.data);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
